<template>
  <div class="homepage-recents">
    <ad-slot mapping="HomeRecents" />
    <div class="homepage-recents__teaser-grid">
      <post-teaser
        v-for="post in posts"
        :key="post.id"
        :post="post"
        :show-masthead="hasChildSiteTeasers"
      />
      <router-link
        v-if="!hasChildSiteTeasers"
        :to="{ name: 'pagedPosts', params: { pageNumber: 1 } }"
        class="homepage-recents__cta button button--block"
        data-test-id="homepage-recents-cta-button"
      >
        {{ linkText }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';
import PostTeaser from '@/components/Posts/PostTeaser.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HomepageRecents',
  components: {
    AdSlot,
    PostTeaser,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['siteHasFeature']),
    hasChildSiteTeasers() {
      return this.siteHasFeature('Child Site Teasers');
    },
    linkText() {
      return this.siteHasFeature('rare_disease') ? 'See what\'s new' : 'Read recent articles';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_homepage-recents';
</style>

<docs>
</docs>
